import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import ApplicationLinkContainer from '../shared/applicationLink/applicationLinkContainer';
import {
    REGISTER_PATH,
    REGISTER_EMAIL_PATH,
    REGISTER_FEDERATED_PATH,
} from '../../../shared/endpoints/account';
import { useStateContext } from '../../modules/stateContext';
import renderQueryString from '../../../shared/urls/renderQueryString';

const RegisterCta = ({ isFederated, skipAgeBracketScreen }) => {
    const userContext = useStateContext();

    return (
        <Fragment>
            <div className="u-padding-bottom-double u-padding-bottom--tablet">
                <Heading level={2} fontScale="sectionHeading">
                    <FormattedMessage id="signIn.noAccount.title" />
                </Heading>
            </div>
            {userContext.featureToggles.regSkipAgeBracketScreen &&
            skipAgeBracketScreen ? (
                <ApplicationLinkContainer
                    href={() => {
                        return `${REGISTER_EMAIL_PATH}${renderQueryString.call(
                            userContext,
                            {
                                ab: 'o13',
                            }
                        )}`;
                    }}
                    primary
                >
                    <FormattedMessage id="signIn.noAccount.link" />
                </ApplicationLinkContainer>
            ) : (
                <ApplicationLinkContainer
                    href={isFederated ? REGISTER_FEDERATED_PATH : REGISTER_PATH}
                    primary
                >
                    <FormattedMessage id="signIn.noAccount.link" />
                </ApplicationLinkContainer>
            )}
        </Fragment>
    );
};

RegisterCta.displayName = 'RegisterCta';

RegisterCta.propTypes = {
    isFederated: PropTypes.bool,
    skipAgeBracketScreen: PropTypes.bool,
};

RegisterCta.defaultProps = {
    isFederated: false,
    skipAgeBracketScreen: false,
};

export default RegisterCta;
